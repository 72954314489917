<template>
    <v-form @submit.prevent="formSubmit">

        <v-row>
            <v-col cols="12" md="4">
                <v-select
                    v-model="account"
                    :disabled="detailsLoading"
                    :error-messages="accountErrors"
                    :items="accounts"
                    :label="$t('invoice.payment.account')"
                    item-text="name"
                    item-value="uuid"
                    outlined
                    @blur="$v.account.$touch()"
                    @input="$v.account.$touch()"
                ></v-select>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="amount"
                    :disabled="detailsLoading"
                    :error-messages="amountErrors"
                    :label="$t('invoice.payment.amount')"
                    :suffix="currency"
                    outlined
                    @blur="$v.amount.$touch()"
                    @input="$v.amount.$touch()"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-select
                    v-model="currency"
                    :disabled="detailsLoading"
                    :error-messages="currencyErrors"
                    :items="currencies"
                    :label="$t('invoice.payment.currency')"
                    outlined
                    @blur="$v.currency.$touch()"
                    @input="$v.currency.$touch()"
                ></v-select>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="4">
                <DatePicker
                    v-model="paid_at"
                    :disabled="detailsLoading"
                    :error-messages="paidAtErrors"
                    :label="$t('invoice.payment.paid_at')"
                    @blur="$v.paid_at.$touch()"
                    @input="$v.paid_at.$touch()"
                ></DatePicker>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="payment_method"
                    :disabled="detailsLoading"
                    :error-messages="paymentMethodErrors"
                    :label="$t('invoice.payment.payment_method')"
                    outlined
                    @blur="$v.payment_method.$touch()"
                    @input="$v.payment_method.$touch()"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="reference"
                    :disabled="detailsLoading"
                    :label="$t('invoice.payment.reference')"
                    outlined
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-textarea
                    v-model="description"
                    :label="$t('invoice.payment.description')"
                    auto-grow
                    outlined
                    rows="3"
                ></v-textarea>
            </v-col>
        </v-row>

        <v-btn
            :loading="detailsLoading"
            color="secondary"
            elevation="2"
            type="submit"
        >
            <span v-if="edit">{{ $t("invoice.payment.update") }}</span>
            <span v-else>{{ $t("invoice.payment.add") }}</span>
        </v-btn>
    </v-form>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import Accounting from "@/helpers/Accounting";
import Currencies from "@/helpers/Currencies";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
    name: "PaymentDetails",
    components: {DatePicker},
    props: ["invoice", "payment"],
    data() {
        return {
            detailsLoading: false,
            accounts: [],
            account: null,
            currencies: Currencies.get(),
            currency: null,
            paid_at: null,
            amount: null,
            payment_method: null,
            reference: null,
            description: null,
            edit: false
        };
    },
    async mounted() {
        this.account = this.amount = this.currency = this.description = this.paid_at = this.payment_method = this.reference = null;

        this.detailsLoading = true;

        let accreq = await Accounting.get("/accounts?fields=name,uuid&per_page=-1&page=1").catch(() => {
        });
        this.accounts = accreq.data.accounts;

        if (this.invoice !== undefined && this.invoice !== null && this.payment !== undefined && this.payment !== null) {
            let details = await Accounting.get("/incomes/invoices/" + this.invoice).catch((error) => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
            });

            for (const paymentElement of details.data["payments"]) {
                if (paymentElement.uuid === this.payment) {
                    this.account = paymentElement.account;
                    this.amount = paymentElement.amount;
                    this.currency = paymentElement.currency_code;
                    this.description = paymentElement.description;
                    this.paid_at = paymentElement.paid_at;
                    this.payment_method = paymentElement.payment_method;
                    this.reference = paymentElement.reference;
                }
            }

            if (this.account != null) {
                this.edit = true;
            }
        }

        this.detailsLoading = false;
    },
    methods: {
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.detailsLoading = true;

                let url = "/incomes/invoices/" + this.$route.params.uuid + "/payments";

                if (this.edit) {
                    url = url + "/" + this.payment;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    account: this.account,
                    amount: this.amount,
                    currency_code: this.currency,
                    description: this.description,
                    paid_at: this.paid_at,
                    payment_method: this.payment_method,
                    reference: this.reference
                }).then(() => {

                    this.detailsLoading = false;

                    let txt = this.$t("invoice.payment.added");
                    if (this.edit) {
                        txt = this.$t("invoice.payment.updated");
                    }

                    this.$store.commit("SET_ALERTS", [{
                        type: "success",
                        text: txt
                    }]);
                    this.$emit("payment-updated");

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.detailsLoading = false;
                });
            }
        }
    },
    mixins: [validationMixin],
    validations: {
        account: {required},
        currency: {required},
        paid_at: {required},
        amount: {required},
        payment_method: {required}
    },
    computed: {
        accountErrors() {
            const errors = [];
            if (!this.$v.account.$dirty) return errors;
            !this.$v.account.required && errors.push(this.$t("invoice.payment.account_required"));
            return errors;
        },
        currencyErrors() {
            const errors = [];
            if (!this.$v.currency.$dirty) return errors;
            !this.$v.currency.required && errors.push(this.$t("invoice.payment.currency_required"));
            return errors;
        },
        paidAtErrors() {
            const errors = [];
            if (!this.$v.paid_at.$dirty) return errors;
            !this.$v.paid_at.required && errors.push(this.$t("invoice.payment.paid_at_required"));
            return errors;
        },
        amountErrors() {
            const errors = [];
            if (!this.$v.amount.$dirty) return errors;
            !this.$v.amount.required && errors.push(this.$t("invoice.payment.amount_required"));
            return errors;
        },
        paymentMethodErrors() {
            const errors = [];
            if (!this.$v.payment_method.$dirty) return errors;
            !this.$v.payment_method.required && errors.push(this.$t("invoice.payment.payment_method_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>